<template>
	<div class="row">
		<div class="col-12 text-right mb-3">
			<b-form-checkbox v-model="showDts" name="check-button" switch>{{
				$t('seeDT')
			}}</b-form-checkbox>
		</div>
		<div
			class="col-12 col-sm-6 mb-3"
			v-for="match in lastMatches"
			:key="match.id"
		>
			<div class="card">
				<div
					v-if="showRound && match.date"
					class="card-header d-flex justify-content-between"
				>
					<strong
						>{{ $tc('round', 1) | toCapitalize }} {{ match.round }}</strong
					>
					<span>{{ match.date }}</span>
				</div>
				<strong v-if="showRound && !match.date" class="card-header text-center"
					>{{ $tc('round', 1) | toCapitalize }} {{ match.round }}</strong
				>
				<div class="wrapper-match">
					<img :src="match.bg_name" alt="Fondo del partido" class="w-100 bg" />
					<div class="match">
						<div class="team team-local">
							<router-link
								:to="{
									name: 'TeamHome',
									params: {
										lang: lang,
										console: console,
										teamSlug: match.team_local_slug,
									},
								}"
							>
								<img
									:src="match.team_local_image"
									alt="Local"
									class="team-img"
								/>
								<h6 class="team-name">{{ match.team_local_name }}</h6>
							</router-link>
							<p v-if="showDts" class="team-dt mb-0">{{ match.local_dt }}</p>
							<!-- Opciones local -->
							<div
								v-if="showDts && match.show_edit_local"
								class="dd-options-results"
							>
								<b-dropdown
									variant="flat"
									no-caret
									v-if="
										(match.played && match.user_type === 'DT') || !match.played
									"
								>
									<template slot="button-content">
										<i class="fa fa-pencil"></i>
									</template>
									<b-dropdown-item
										v-if="
											!match.played &&
												(match.user_type === 'SUPER' ||
													match.user_type === 'ADMI' ||
													match.user_type === 'FULL')
										"
										:to="{
											name: 'TeamBackResultsEdit',
											params: {
												lang: lang,
												console: console,
												fixtureId: match.id,
												teamId: match.team_local,
											},
										}"
										>{{ $t('results') }}</b-dropdown-item
									>
									<b-dropdown-item
										v-else
										:to="{
											name: 'TeamBackResultsEdit',
											params: {
												lang: lang,
												console: console,
												fixtureId: match.id,
												teamId: match.team_local,
											},
										}"
										>{{ $t('results') }}</b-dropdown-item
									>
									<b-dropdown-item
										v-if="match.user_type === 'DT'"
										@click="openModalVideos(match.id)"
										>{{ $t('editVideos') }}</b-dropdown-item
									>
									<b-dropdown-item
										v-if="match.user_type === 'DT'"
										@click="openModalImages(match.id)"
										>{{ $t('addPictures') }}</b-dropdown-item
									>
								</b-dropdown>
							</div>
						</div>
						<div
							v-if="match.played || (match.check_local || match.check_visit)"
							class="results"
						>
							<strong>{{ match.local_goals }} - {{ match.visit_goals }}</strong>
						</div>
						<div v-else class="results"></div>
						<div class="team team-visit">
							<router-link
								:to="{
									name: 'TeamHome',
									params: {
										lang: lang,
										console: console,
										teamSlug: match.team_visit_slug,
									},
								}"
							>
								<img
									:src="match.team_visit_image"
									alt="Local"
									class="team-img"
								/>
								<h6 class="team-name">{{ match.team_visit_name }}</h6>
							</router-link>
							<p v-if="showDts" class="team-dt mb-0">{{ match.visit_dt }}</p>
							<!-- Opciones visit -->
							<div
								v-if="showDts && match.show_edit_visit"
								class="dd-options-results dd-options-results-visit"
							>
								<b-dropdown
									variant="flat"
									no-caret
									right
									v-if="
										(match.played && match.user_type === 'DT') || !match.played
									"
								>
									<template slot="button-content">
										<i class="fa fa-pencil"></i>
									</template>
									<b-dropdown-item
										v-if="
											!match.played &&
												(match.user_type === 'SUPER' ||
													match.user_type === 'ADMI' ||
													match.user_type === 'FULL')
										"
										:to="{
											name: 'TeamBackResultsEdit',
											params: {
												lang: lang,
												console: console,
												fixtureId: match.id,
												teamId: match.team_visit,
											},
										}"
										>{{ $t('results') }}</b-dropdown-item
									>
									<b-dropdown-item
										v-else
										:to="{
											name: 'TeamBackResultsEdit',
											params: {
												lang: lang,
												console: console,
												fixtureId: match.id,
												teamId: match.team_visit,
											},
										}"
										>{{ $t('results') }}</b-dropdown-item
									>
									<b-dropdown-item
										v-if="match.user_type === 'DT'"
										@click="openModalVideos(match.id)"
										>{{ $t('editVideos') }}</b-dropdown-item
									>
									<b-dropdown-item
										v-if="match.user_type === 'DT'"
										@click="openModalImages(match.id)"
										>{{ $t('addPictures') }}</b-dropdown-item
									>
								</b-dropdown>
							</div>
						</div>
					</div>
				</div>
				<div v-if="showDts" class="w-100 text-center pb-3 btn-show-match">
					<b-button
						v-if="match.played"
						:to="{
							name: 'DivisionMatchDetail',
							params: {
								lang: lang,
								console: console,
								divisionSlug: match.division_slug,
								fixtureId: match.id,
							},
						}"
						variant="flat"
					>
						<i class="fa fa-eye"></i>
					</b-button>
					<b-button
						class="dd-options-results-center"
						variant="flat"
						v-if="
							match.played &&
								(match.user_type === 'SUPER' ||
									match.user_type === 'ADMI' ||
									match.user_type === 'FULL') &&
								showDts
						"
						:to="{
							name: 'TeamBackResultsEditMatch',
							params: { lang: lang, console: console, fixtureId: match.id },
						}"
					>
						<i class="fa fa-pencil"></i>
					</b-button>
				</div>
			</div>
		</div>
		<modal-videos :fixture-id="fixtureId" :videos="videos" />
		<modal-images :fixture-id="fixtureId" :images="images" />
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalVideos from '../Back/Team/Results/ModalVideos'
import ModalImages from '../Back/Team/Results/ModalImages'

export default {
	components: {
		ModalVideos,
		ModalImages,
	},
	props: {
		lastMatches: {
			required: true,
			type: Array,
		},
		showRound: {
			required: false,
			type: Boolean,
			default: true,
		},
		showDetails: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showDts: false,
			fixtureId: 0,
			videos: [],
			images: [],
		}
	},
	computed: {
		...mapGetters(['lang', 'console', 'teamFront']),
	},
	methods: {
		openModalImages(fixtureId) {
			this.fixtureId = fixtureId
			const path = `auth/${this.lang}/${
				this.console
			}/teams/results/${fixtureId}/images/show`
			this.$axios.get(path).then((response) => {
				this.images = response.data.data
				this.$root.$emit('bv::show::modal', 'modalImages')
			})
		},
		openModalVideos(fixtureId) {
			this.fixtureId = fixtureId
			const path = `auth/${this.lang}/${
				this.console
			}/teams/results/${fixtureId}/videos/show`
			this.$axios.get(path).then((response) => {
				this.videos = response.data.data
				this.$root.$emit('bv::show::modal', 'modalVideos')
			})
		},
	},
}
</script>
